#choose_user{
  .list_user{
    width: 100%;
    height: 360px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #7C7B7B;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .user{
      &:last-child{
        border: none
      }
    }
  }
}