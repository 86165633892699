.modal_update_choose_img {
  #header_tab {
    .nav-item .nav-link {
      color: #071437;
    }

    .nav-item .nav-link.active {
      color: #009ef7;
    }
  }

  .tab-content {
    width: 100%;
    height: 420px;

    .upload_image {
      width: 100%;
      height: 389px;
      background-color: #E8E8E8;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer !important;

      input {
        position: absolute;
        width: 100%;
        height: 389px;
        opacity: 0;
        cursor: pointer;
      }
    }

    #tab_choose {
      width: 100%;
    }
  }

  .listImg {
    width: 100%;
    max-height: 389px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #7C7B7B;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .images {
      width: 120px;
      height: 120px;
      margin: 0 8px 8px 0;
      border: 1px solid #e4e4e5;
      border-radius: 8px;
      overflow: hidden;

      &:last-child, &:nth-child(7n) {
        margin-right: 0;
      }

      img {
        width: 118px;
        height: 118px;
        object-fit: cover;
        cursor: pointer;
      }
    }
  }
}