//
// Main
//

// Body
body {
  background-color: $page-bg;
}

#modal_confirm{
  .modal-dialog {
    width: 600px;
    height: auto;
    background: #FFFFFF;
    border-radius: 8px;

    .modal-content {
      border: none;

      .modal-header {
        padding: 19px 19px 0 !important;
      }

      .icon {
        margin-bottom: 24px;
      }

      h2 {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #000000;
        margin-bottom: 8px;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: #333333;
        margin-bottom: 32px;
      }

      .action {
        margin-bottom: 32px;

        button {
          width: 190px;
          height: 44px;
          font-weight: 700;
          font-size: 14px;
          border-radius: 2px;
          margin: 0 12px;
        }

        button.btn-cancel {
          border: 1.5px solid #858585;
          color: #858585;
          background-color: transparent;
        }

        button.btn-confirm {
          color: white;
          background: #29AB56;
        }
      }
      .bt_remove{
        color: #FFFFFF;
        background: #ED2121;
        border: 1.5px solid #ED2121;
        &:hover {
          color: #FFFFFF;
        }
      }

    }
  }
}

// Font color from Content background color
.text-page-bg {
  color: $page-bg;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
  // Containers
  .container,
  .container-xxl,
  .container-fluid {
    padding: 0 get($content-spacing, desktop);
  }

  // Wrapper
  .wrapper {
    transition: padding-left get($aside-config, transition-speed) ease,
      margin-right get($aside-config, transition-speed) ease;

    // Aside default enabled and aside fixed modes
    .aside-enabled.aside-fixed & {
      transition: padding-left get($aside-config, transition-speed) ease;
      padding-left: get($aside-config, width);
    }

    // Aside default enabled, aside fixed and aside minimize modes
    .aside-enabled.aside-fixed[data-kt-aside-minimize='on'] & {
      transition: padding-left get($aside-config, transition-speed) ease;
      padding-left: get($aside-config, minimized-width);
    }

    // Fixed header mode
    .header-fixed & {
      padding-top: get($header-config, fixed, height, desktop);
    }

    // Fixed header & fixed toolbar modes
    .header-fixed.toolbar-fixed & {
      padding-top: calc(#{get($header-config, fixed, height, desktop) - 60} + var(--kt-toolbar-height));
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  // Containers
  .container,
  .container-xxl,
  .container-fluid {
    max-width: none;
    padding: 0 get($content-spacing, tablet-and-mobile);
  }

  // Wrapper
  .wrapper {
    // Fixed header mode
    .header-tablet-and-mobile-fixed & {
      padding-top: get($header-config, fixed, height, tablet-and-mobile);
    }

    // Fixed header & fixed toolbar modes
    .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed & {
      padding-top: calc(
        #{get($header-config, fixed, height, tablet-and-mobile)} + var(--kt-toolbar-height-tablet-and-mobile)
      );
    }
  }
}
